@import url('reset.css');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

html, body {
  /*overscroll-behavior: contain;*/
  font-size: 10px;
}

@media (max-width: 800px) {
  body {
    /* предотвращает обновление страницы и навигацию вперёд/назад при достижении границ прокрутки */
    overscroll-behavior-y: auto;
  }
}

/* Стили для десктопов */
@media (min-width: 801px) {
  body {
    /* Позволяет стандартное поведение прокрутки на десктопах */
    overscroll-behavior-y: none;
  }
}


/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
